import React from "react";
import "react-phone-number-input/style.css";
import Input from "react-phone-number-input/input";

export default function InfoBox({
	inputStatus,
	prompt,
	numberInput,
	setNumberInput,
	checkPhone,
	code,
	setCode,
	checkCode,
}) {
	const phoneForm = () => {
		return (
			<>
				<form>
					<Input
						type="tel"
						defaultCountry="US"
						value={numberInput}
						limitMaxLength={true}
						placeholder="cell #"
						style={{ width: "47%", textAlign: "center" }}
						className="yellow  center bb  b--white w-70 w-50-ns   f4"
						onChange={(number) => setNumberInput(number)}
					/>
					<button
						onClick={(e) => checkPhone(e)}
						className="mv3 green tracked f3 grow bg-black hover-red b br bl bw2 bt-0 bb-0  b--yellow"
					>
						SEND IT
					</button>
				</form>
			</>
		);
	};
	const codeForm = () => {
		return (
			<>
				<form>
					{" "}
					<input
						type="number"
						onChange={(e) => setCode(e.target.value)}
						placeholder="2FA"
						value={code}
						max={999999}
						className="  w-40 bb  b--white  f3 b"
						autocomplete="one-time-code"
						inputmode="numeric"
						pattern="[0-9]*"
					/>
					<br />
					<button
						onClick={(e) => checkCode(e)}
						className="mv3 tracked  f3 grow bg-black hover-red b br bl bw2 bt-0 bb-0 green b--yellow"
					>
						VERIFY IT
					</button>
				</form>
			</>
		);
	};
	const inputRender = () => {
		switch (inputStatus) {
			case 0:
				return phoneForm();
			case 1:
				return codeForm();
			case 3:
				return null;
			case 4:
				return (
					<span className="red f3">
						THERE HAS BEEN A FATAL SERVER ERROR contact{" "}
						<a
							className="link dark-red b f3-l f5-s"
							href="mailto:clerk@deathonwax.com?subject=Server Issue &body= Good -insert TOD-, \n\n I am writting you today to inform your server failed, however I would still like to come to the party; \n\n -insert request- \n\n -best\n -insert name-"
						>
							<span className="white"> Clerk@deathonwax.com</span>
						</a>
					</span>
				);
			default:
				return <div>"I'm a default div, this site is broken KYS"</div>;
		}
	};

	return (
		<div className="w-60-l center-l flex-l ">
			<div className=" w-50-l ml-40 mt2-l bt bw3 bg-yellow  pv2 tc ">
				<span className="b  f2	 ">There Will be a Party</span>

				{/* <p className="ml4 tracked">
					It will feature{" "}
					<a
						className="link grow "
						href="https://trebuchet.engineering/#/nospeed"
						target="_blank"
						rel="noopener noreferrer"
					>
						<span className="green hover-dark-red underline ">
							these speakers
						</span>
					</a>
				</p> */}

			</div>
			<div className=" mt2 w-60-l  bg-black yellow b--yellow  bb bw3">
				<div className="f4-l pt1 b  mt2-l pt3 mb3 yellow tc">{prompt}</div>
				<div className="tc ">{inputRender()}</div>
			</div>
		</div>
	);
}
