import React, { useState, useEffect } from "react";
import "./App.css";
import "tachyons";
import logo from "./imgs/logo-move.gif";
import altLogo from "./imgs/logo-static.png";
import InfoBox from "./components/InfoBox";

const URL = process.env.REACT_APP_SERVER_URL;
function App() {
	const [numberInput, setNumberInput] = useState("+1");
	const [code, setCode] = useState("");
	const [inputStatus, setInputStatus] = useState(0);
	const [prompt, setPrompt] = useState("Details via Text");
	useEffect(() => {
		playAudio();
	}, []);

	const playAudio = () => {
		const audioEl = document.getElementsByClassName("audio-element")[0];
		audioEl.play();
		audioEl.loop = true;
	};

	const checkPhone = async (e) => {
		e.preventDefault();
		const number = numberInput;
		if (number.length < 12) return alert("Enter cell # with + country code");
		try {
			const serverRes = await fetch(`${URL}/api/numberverify`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ number }),
			});
			switch (serverRes.status) {
				case 201:
					alert("Sup, input the 6 digit code sent to you");
					setPrompt("Check your Phone");
					setInputStatus(1);
					break;
				case 406:
					setInputStatus(3);
						setPrompt("You are already in the books");
					break;
				case 400:
					setPrompt("Client/Server fuzzy, TRY AGAIN");
					break;
				case 503:
					setInputStatus(3);
					setPrompt(
						"make sure you have CORRECT COUNTRY CODE +1 etc or please email clerk@deathonwax.com"
					);
					break;
				case 500:
				default:
					setInputStatus(4);
			}
		} catch (err) {
			console.error(err);
			setInputStatus(4);
		}
	};

	const checkCode = async (e) => {
		e.preventDefault();
		const number = numberInput;
		const event = "summer2022";
		if (!code || code.length !== 6) {
			return alert("Input the Code texted to you, all 6 digits  ʕ •ᴥ•ʔ");
		}
		try {
			const serverRes = await fetch(`${URL}/api/codeverify`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ code, number, event }),
			});
			switch (serverRes.status) {
				case 200:
					setInputStatus(3);
										setPrompt("GOOD 2 GO - °ᴥ°");


					break;
				case 406:
					setPrompt("Invalid code, try again");
					break;
				case 400:
					setPrompt("Client/Server fuzzy, TRY AGAIN");
					break;
				case 500:
				default:
					setInputStatus(4);
			}
		} catch (err) {
			console.error(err);
			setInputStatus(4);
		}
	};

	return (
		<>
			<div className="black" style={{ backgroundColor: "#EBEBE9" }}>
				<div className="cf mh2 pt1 ">
					<InfoBox
						code={code}
						numberInput={numberInput}
						checkPhone={checkPhone}
						checkCode={checkCode}
						setNumberInput={setNumberInput}
						inputStatus={inputStatus}
						setCode={setCode}
						prompt={prompt}
					/>
				</div>
				<div className="logoContainer ">
					<img className="" src={logo} alt={altLogo} />
					<a
						href="https://www.deathonwax.com/"
						target="_blank"
						rel="noopener noreferrer"
					>
						<div className="link2home  "></div>
					</a>
				</div>
			</div>
			<div className="tc pt3 purple">© 2021</div>
			<div>
				<audio className="audio-element">
					<source src="/idme.mp3"></source>
				</audio>
			</div>
		</>
	);
}

export default App;
